.main2 {
    display: flex !important;
    font-family: 'Nunito', sans-serif !important;
    width: 100vw !important;
  }
  
.sidebar_container {
    height: 100vh !important;
    position: sticky !important;
    top: 0 !important;
    left:0 !important;
    display: flex !important;
    justify-content: flex-start !important;
    color: white !important;
    align-items: center !important;
  
  }
 
  .sidebar{
    display: flex;
    width: 14rem;
    height: 93%;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    padding: 15px;
    margin: 10px;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(5.5px);
    border-radius:10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
  }
  .lines_icon{
    display: flex;
    align-self: flex-end;
    border-radius: 5px;
  }
  
  .profile{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  }

  .profile:hover{
    cursor: pointer;
  }

  .profile>img{
    width: 100%;
    margin-top: 30px;
     margin-bottom: -10px !important;
  }
  
  .groups{
    display: flex;
    flex-direction: column;
    width: 100%
  }
  .group{
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: 'Yantramanav', sans-serif !important;
  }

  h3{
    font-size: 16px !important;
    margin: 10px 0px 10px 0px;
    font-family: 'Yantramanav', sans-serif;
    margin-top: 15px !important;
  }

  
 