.item{
    display: flex;
    padding: 6px 10px 6px 10px;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
    font-family: 'Baloo 2', cursive;
    justify-content: flex-start;
    align-items: center;
}
.icon{
    margin-right: 0.5rem;
    margin-top: 6px
}
.pointname{
    margin-top: 6px !important;

}