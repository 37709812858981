.pdf{
    display: flex;
    width: 100vw !important;
    height: 100vh  !important;

}
.billprintmain{
    display: flex;
    width: 100vw !important;
    height: 100vh !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: orange;
    flex-direction: column !important;
}

.loadingbill{
    display: flex;
    width: 90% !important;
    height: 90% !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 10px !important;
    background-color: white !important;
    align-self: center !important;
}

.loadingfont{
    font-family: 'Yantramanav', sans-serif !important;
    font-weight: bold;
}

.load-more {
    text-align: center;
    padding: 20px;
    cursor: pointer;
  }