.table-containerthev {
    width: 60% !important;
    height: 66vh !important;
    overflow-y: auto;
    border: 1px solid salmon;
    border-radius: 10px;
    padding: 20px;
}

.subheadthev {
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    font-weight: bold;
    margin-left: 20px;
}
.lblthev{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    border: 1px solid gray;
    background-color: var(--t4);
    padding: 5px;
    margin-top: 5px;
}

.ic2{
    height: 20px;
    width: 20px;
    align-self: flex-end;
}
.ic2none{
    height: 20px;
    width: 20px;
    display: none;
    align-self: flex-end;
}
.ic2:hover{
    cursor: pointer;
}

