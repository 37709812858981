.chart {
    display: flex;
    flex-direction: row;
}

.chartreport{
    display: flex;
    width: 80vw !important;
    flex-direction: column;
    justify-content: center;
}

.uchaldiv{
    display: flex;
    width: 80vw !important;
    flex-direction: row;
    justify-content: center;
    padding: 5px;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    padding-left: -50px;
}

.uchalbox{
    display: flex;
    background-color: var(--t3dark);
    padding: 15px;
    border: 1px solid var(--t3dark);
    border-radius: 5px;
}

.uchalbox2{
    display: flex;
    background-color: var(--t4dark);
    padding: 15px;
    border: 1px solid var(--t4dark);
    border-radius: 5px;
}

.uchalbox3{
    display: flex;
    background-color: var(--t2dark);
    padding: 15px;
    border: 1px solid var(--t2dark);
    border-radius: 5px;
}

.uchalbox:hover,
.uchalbox2:hover,
.uchalbox3:hover {
    cursor: pointer;
    transform: scale(1.05);
}
.smallhead {
    margin-left: 5px;
    border: none !important;
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px !important;
    width: 400px !important;
    font-weight: bold;
    color: var(--label) !important;
}

.smallhead2{
    margin-left: 5px;
    border: none !important;
    font-family: 'Nunito', sans-serif !important;
    font-size: 20px !important;
    width: 400px !important;
    font-weight: bold;
    color: var(--label) !important;
}

.literrow {
    display: flex;
    width: 84vw !important;
    align-items: center;
    justify-content: space-between;
    gap: 20px !important;
}

.litertype {
    display: flex;
    flex-direction: row;
    width: 500px !important;
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px !important;
    gap: 10px !important;
}

.linechart{
    width: 480px;
    background-color: #D3D3D8;
    height: 0.5px;
    margin-top: 15px;
    margin-left: 10px;
    align-self: center !important;
  }