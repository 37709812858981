.sanklanmain {
    display: flex;
    flex-direction: column;
    width: 75vw;
}

.dddd{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.refresh {
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: transform 0.3s ease;
    /* Add transition for smooth effect */
}

.refresh:hover {
    transform: rotate(180deg);
    /* Rotate 180 degrees on hover */
}

.firstrowsank {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    margin-top: -15px !important;
    width: 100% !important;
}

.firstrow2 {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-between;
    width: 90vw;
    align-items: center;
    margin-top: 5px !important;
}

.subheadsank {
    font-family: 'Nunito', sans-serif !important;
    font-size: 13px !important;
    font-weight: bold !important;
    user-select: none;
    transition: transform 0.3s ease;
}

.prev {
    font-family: 'Nunito', sans-serif !important;
    font-size: 15px;
    font-weight: bold;
    user-select: none;
    color: var(--label);
}

.label2 {
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    font-weight: bold;
    user-select: none;
    color: var(--label);
}

.subheadsankcolor {
    font-family: 'Nunito', sans-serif !important;
    font-size: 16px;
    font-weight: bold;
    user-select: none;
    color: var(--pink);
    transition: transform 0.3s ease;
}

.subheadsankgreen {
    font-family: 'Nunito', sans-serif !important;
    font-size: 12px !important;
    font-weight: bold;
    color: var(--green);
    user-select: none;
    transition: transform 0.3s ease;
}

.inputfont {
    font-family: 'Nunito', sans-serif !important;
    font-size: 16px;
    font-weight: bold;
}

.linesank {
    width: 81vw;
    background-color: #D3D3D8;
    height: 0.5px;
    margin-top: 5px;
}

.row1sank {
    display: flex;
    flex-direction: row;
    gap: 5px;

}

.mb-2>#name {
    width: 270px !important;
}



table#myTable th.thsmallkno {
    background-color: var(--t3) !important;
    width: 200px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
}

table#myTable th.thsmall {
    background-color: var(--t1) !important;
    width: 200px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
}

table#myTable th.thactionsank {
    background-color: var(--t1) !important;
    width: 150px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
}

table#myTable td.thactionsank {
    background-color: var(--t5dark) !important;
    width: 150px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
}

table#myTable td.thsmallred {

    width: 200px !important;
    color: rgb(233, 9, 9) !important;
    font-family: 'Nunito', sans-serif !important;
    font-size: 15px !important;
    font-weight: bolder !important;
}

table#myTable td.thsmallgreen {

    width: 200px !important;
    color: rgb(40, 179, 63) !important;
    font-family: 'Nunito', sans-serif !important;
    font-size: 15px !important;
    font-weight: bolder !important;
}

table#myTable td.thsmall {
    width: 200px !important;
    color: rgb(27, 26, 26);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    font-weight: bold !important;
}

table#myTable td.thsmall2 {
    width: 200px !important;
    color: rgb(15, 14, 14) !important;
    font-family: 'Nunito', sans-serif !important;
    font-size: 15px;
}

table#myTable th.thlarge {
    background-color: var(--t1) !important;
    width: 600px !important;
}

table#myTable td.thlarge {
    width: 600px !important;
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    color: rgb(27, 26, 26);
}

.icsank:hover {
    cursor: pointer;
    scale: 1.1;
}

.colmain {
    display: flex;
    width: 100%;
}

.rowsank {
    display: flex;
    flex-direction: row;
    gap: 5px;
}


.tboxbelow {
    display: flex;
    width: 80vw !important;
    flex-direction: row;
    gap: 5px !important;
    border-radius: 5px;
    border: 1px solid var(--t1);
    background-color: whitesmoke !important;
    margin-top: 20px;
    margin-left: 80px !important;
    justify-content: center !important;
    align-items: center !important;
    align-self: center !important;
    transition: transform 0.3s ease;
}

.tboxbelow:hover {
    cursor: pointer;
    transform: translateY(-4px);

}

.toboxbelow2 {
    display: flex;
    flex-direction: row;
    width: 80vw !important;
    gap: 10px;
    margin-top: 20px;
    justify-content: center !important;
    align-items: center !important;
    align-self: center !important;
    margin-left: 80px !important;
    transition: transform 0.3s ease;
}

.toboxbelow2:hover {
    cursor: pointer;
    transform: translateY(-4px);
    
  }

.mhbox {
    display: flex;
    width: 40% !important;
    padding: 5px;
    flex-direction: row;
    gap: 10px !important;
    border-radius: 5px;
    border: 1px solid var(--t1);
    background-color: var(--t3dark) !important;
    flex-direction: row;
    justify-content: center !important;
    align-items: center !important;
    align-self: center !important;
}

.mhgaybox {
    display: flex;
    width: 20% !important;
    padding: 5px;
    flex-direction: column; 
    gap: 10px !important;
    border-radius: 5px;
    border: 1px solid var(--t1);
    background-color: var(--t3dark) !important;
    flex-direction: row;
    justify-content: center !important;
    align-items: center !important;
    align-self: center !important;
}

.tbox {
    display: flex;
    padding: 10px;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid var(--t1);
    background-color: var(--t1dark) !important;
    height: auto;
    overflow: hidden;
    align-items: center;
    transition: height 2.0s ease-in-out;
    width: 350px !important;
}

.expanded {
    display: flex;
    position: absolute;
    margin-top: -31px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid var(--t1);
    background-color: var(--t1dark) !important;
    right: -200px !important;
    height: 400px;
    width: 550px !important;
    z-index: 2;
}


.tbox2 {
    display: flex;
    padding: 10px;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;
    height: auto;
    overflow: hidden;
    align-items: center;
    transition: height 2.0s ease-in-out;
    width: 350px !important;
}

.expanded2 {
    display: flex;
    position: absolute;
    margin-top: -31px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    right: -200px !important;
    height: 400px;
    width: 550px !important;
    z-index: 2;
}

.sankverti {
    display: flex;
    flex-direction: column;
}

.tbox-container {
    position: relative;
}

.noti {
    margin-right: -30px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    margin-top: -10px !important;
}

.form-check {
    font-weight: bold !important;
    font-size: 14px !important;
    margin-top: -10px !important;
}


.tbox:hover {
    cursor: pointer;
}

.table-containersank {
    width: 90% !important;
    height: 50vh !important;
    overflow-y: auto;
    border: 1px solid salmon;
    border-radius: 10px;
    padding: 20px;
}

#myTable {
    width: 98% !important;
}

.table-containersank::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
}

/* Customize the scrollbar style */
.table-containersank::-webkit-scrollbar {
    width: 10px;
}

.table-containersank::-webkit-scrollbar-thumb {
    background-color: #FF907B;
    border-radius: 5px;
    /* Adjust the value to control the roundness */
}

.table-containersank::-webkit-scrollbar-thumb:hover {
    background-color: #FF907B;
}

.subheadsank:hover {
    cursor: pointer;
    transform: translateY(-4px);
}

.subheadsankcolor:hover {
    cursor: pointer;
    transform: translateY(-4px);
}

.subheadsankgreen {
    cursor: pointer;
    transform: translateY(-4px);
}

.subheadsankgreen2 {
    cursor: pointer;
    font-size: 13px;
    color: var(--green);
    font-weight: bolder;
}

.back2 {
    width: 20px;
    height: 20px;
    margin-left: 15px;
}

.back2:hover {
    cursor: pointer;
    scale: 1.01;
}

.row1 {
    display: flex;
}

.mb-2>#name {
    width: 250px !important;
}

.mb-2>#time {
    width: 200px !important;
}

#date {
    width: 150px !important;
}

.tt {
    display: flex;
    flex-direction: column !important;
}

.mb-2>#type {
    width: 110px !important;
}

.mb-2>#digri {
    width: 100px !important;
}

.tt>#amt {
    width: 100px !important;
}


.checkboxes {
    display: flex;
    flex-direction: column;
    gap: 0px;
}