.maincon{
    display: flex;
    flex-direction: column;
    width: 60vw;
    margin-top: 1px !important;
 
}
.line{
    width: 100%;
    background-color: #D3D3D8;
    height: 0.5px;
    border-color : red;
    margin-top: 5px;
    padding: 0.5px;
  }
.heading1{
    color: rgb(90, 87, 87) !important;
    text-shadow: none !important;
    border: none !important;
    text-shadow: 1px 0px 2px #8dc4f0;
    font-size: 1.5rem;
    margin-top: 20px;
    font-family: 'Yantramanav', sans-serif !important;
    display: flex;
    max-height: 200px; /* Set the initial height value */
    transition: opacity 0.5s;
}

.heading1.hidden {
    opacity: 0;
    pointer-events: none;
    margin-top: 0px;
    transition: opacity 0.5s;
    display: none;
    
}

.cust{
   display: flex;
   flex-direction: column;
   transition: opacity 0.5s;

}

.cust.hidden {
    opacity: 0;
    pointer-events: none;  
}

.Dashcontents{
    display: flex;
    flex-direction: row;
    margin-top: 1px;
    max-height: 200px; /* Set the initial height value */
    transition: opacity 1.5s, max-height 1.0s;
}

.Dashcontents.hidden {
    opacity: 0;
    pointer-events: none;
    max-height: 0;
    margin-top: 10px;
}

.secrow{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: start;
    align-items: center;
}
.leftsecrow{
    width: 70%;
    display: flex;
    flex-direction: row;
}
.imgcon{
    position: relative;
    width: 30%;
    justify-content: center;
    align-items: center;
    bottom: 0;
    height: 100%;
    
}

.img{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 360px;
    height: 300px;
}

.line{
    width: 100%;
    background-color: #D3D3D8;
    height: 0.5px;
    margin-top: 5px;
  }

.back{
    width: 40px;
    height: 40px;
    cursor: pointer;
}


.imgcowdash{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 340px;
    height: 250px;
}


.custbox{
    display: flex;
    width: 60vw;
    height: 100%;
    display: flex;
    margin-top: 20px;
    margin-left: 5px;
}

form{
    width: 75%;
    margin-left: 10px;
}
input{
    margin-top: 5px;
}
.mb-2{
    margin-top: 10px !important;
}

.noteblue{
    font-family: 'Nunito', sans-serif !important;
    font-size: 13px;
    font-weight: bold;
    color: var(--green);
    margin-top: 5px;
}

.noteblack{
    font-family: 'Nunito', sans-serif !important;
    font-size: 13px;
    font-weight: bold;
    margin-top: 15px;
}

.noteblack2{
    font-family: 'Nunito', sans-serif !important;
    font-size: 13px;
    font-weight: bold;
    margin-top: 5px;
}

.qrimg{
    display: flex;
    height: 500px;
    width: 500px;
    margin-top: -140px;
    margin-bottom: -150px;

}

.row1{
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.row1form {
    display: flex;
    width: 100%;
}

.expiry {
    display: flex;
    flex-direction: column !important;
    width: 100%;
    margin-left: 19px;
}

.rowbox{
    display: flex;
    margin-top: 70px;
    margin-right: 90px;
    transition: opacity 0.5s;
 
}

.rowbox.hidden {
    opacity: 0;
    pointer-events: none;  
    margin-top: 0px;
    display: none;
}

.first{
    display: flex;
    width: 15%;
}
.second{
    display: flex;
    width: 85%;
    flex-direction: column;
}
.firstspan{
    margin-top: 15px;
    position: relative;
    transition: transform 0.5s ease;
}
.infospan{
    margin-top: 7px;
    cursor: pointer;
    transition: transform 0.5s ease;
}


.firstspan:hover{
    cursor: pointer;
    font-weight: bold;
    transform: translateX(3%);
    color: var(--label);
}

.infospan:hover{
    cursor: pointer;
    font-weight: bold;
    transform: translateX(3%);
    color: var(--label);
}


.rowaudit{
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.mainconaudit{
    display: flex;
    flex-direction: column;
    width: 22vw !important;
    margin-top: 1px !important;
}

.mainconaudit2{
    display: flex;
    flex-direction: column;
    width: 30vw !important;
    margin-top: 1px !important;
}


.lineaudit{
    width: 50vw;
    background-color: #D3D3D8;
    height: 0.5px;
    border-color : red;
    margin-top: 5px;
  }

