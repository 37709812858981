.khadyamain {
    display: flex;
    flex-direction: column;
    width: 70vw;
    padding: 10px;
}

.hhhh>#name{
    width: 655px !important;
}

.hhhh>#amt{
    width: 140px !important;
}

.firstrowkhadya {
    display: flex;
    flex-direction: row !important;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-top: -25px !important;
    padding-left: 20px;
}

.colmainkhadya {
    display: flex;
    width: 90%;
}

.inputfontkhlarge {
    font-family: 'Nunito', sans-serif !important;
    font-size: 16px;
    font-weight: bold;
    width: 200px !important;
}

.lblredkh {
    font-family: 'Nunito', sans-serif !important;
    font-size: 13px;
    font-weight: bold;
    margin-left: 5px;
    color: var(--label);
    width: 200px !important;
}
.inkh {
    font-family: 'Nunito', sans-serif !important;
    font-size: 16px;
    font-weight: bold;
    /* Increase the width value to accommodate the content */
    width: 100px !important; /* or a value that fits your content */
}

.kkh>input{
    width: 100px !important;
}

#amt{
    width: 130px !important;
    font-weight: bold;

   
}

.linekh {
    width: 80vw;
    background-color: #D3D3D8;
    height: 0.5px;
    margin-top: 5px;
}

.table-containerkh {
    width: 100% !important;
    height: 40vh !important;
    overflow-y: auto;
    border: 1px solid salmon;
    border-radius: 10px;
    padding: 20px;
}


table#myTablepur th.thsmall {
    font-size: 14px;
    font-weight: bold !important;
    background-color: var(--t1dark);
}

table#myTablepur td.thsmall {
    font-size: 14px;
     font-weight: bold !important;

}

table#myTablepur th.thsmall2 {
    font-size: 14px;
    font-weight: bold !important;
    background-color: var(--t1dark);
    width: 500px !important;
}

table#myTablepur td.thsmall2 {
    font-size: 14px;
    font-weight: bold !important;
    background-color: var(--t1dark);
    width: 200px !important;
}

#myTablepur{
    height: 95% !important;
}

.row1khpur {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: auto !important;
    width: 60vw !important;
    margin-top: -10px !important;

}

.rightkhpur{
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    border: 1px lightgray solid;
    border-radius: 5px;
    padding: 5px;
    padding-right: 10px;
    width: 200px;
    justify-content: center;
}

.rightkhpur:hover{
    transform: translateY(-1px);
    background-color: lightblue;
}
