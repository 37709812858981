.thsmallgreenbonus{
    width: 200px !important;
    color: var(--green) !important;
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    font-weight: bold !important;
    text-align: center;
    
}

.bonustext{
    width: 120px !important;
    text-align: center !important;
    background-color: rgb(253, 250, 205) !important;
}

.bonustext2{
    width: 120px !important;
    text-align: center !important;
}

.bonusbox{
    display: flex;
    margin-left: 30px !important;
}