:root {
    --t1: #f7ebf4;
    --t2: #eddcfd;
    --t3: #ffd0c7;
    --t4: #c9eeff;
    --t5: #d9ffc6;
    --t6: #f7ebf4;
    --label: rgb(156, 44, 24);
    
    --t1dark: #F0D9EA;
    --t2dark: #eddcfd;
    --t3dark: #ffd0c7;
    --t4dark: #c9eeff;
    --t5dark: #d9ffc6;
    --t6dark: #f7ebf4;
  
    --t1darkextra: #CB7DB8;
    --t2darkextra: #C894F9;
    --t3darkextra: #FF907B;
    --t4darkextra: #06a6f0;
    --t5darkextra: #4AE100;
    --t6darkextra: #CB7DB8;
  
    --pink: #fe104d;
    --green: #195f17;
    --on: #45c505;
    --greenlight: #d6fed5;
  
  }
  
  .App {
    display: flex;
    font-family: 'Nunito', sans-serif;
    background: linear-gradient(179deg, #176eb5, #d545d7);
  
  }
  
  
  
  
  
  
  
  
  