.khadyamain {
    display: flex;
    flex-direction: column;
    width: 80vw;
    padding: 10px;
}

.firstrowkhadya {
    display: flex;
    flex-direction: row !important;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-top: -25px !important;
    padding-left: 20px;
}

.colmainkhadya {
    display: flex;
    width: 100%;
}

.inputfontkhlarge {
    font-family: 'Nunito', sans-serif !important;
    font-size: 16px;
    font-weight: bold;
    width: 200px !important;
}

.lblredkh {
    font-family: 'Nunito', sans-serif !important;
    font-size: 13px;
    font-weight: bold;
    margin-left: 5px;
    color: var(--label);
    width: 200px !important;
}
.inkh {
    font-family: 'Nunito', sans-serif !important;
    font-size: 16px;
    font-weight: bold;
    /* Increase the width value to accommodate the content */
    width: 100px !important; /* or a value that fits your content */
}

.kkh>input{
    width: 100px !important;
}

.kkh2>#amt{
    width: 150px !important;
}

.u>#khname{
    width: 400px !important;
}

#amt{
    width: 130px !important;
    font-weight: bold;
}

.divider{
    margin-left: 20px;
    margin-right: 20px;
}

.linekh {
    width: 80vw;
    background-color: #D3D3D8;
    height: 0.5px;
    margin-top: 5px;
}

.table-containerkhsale {
    width: 85% !important;
    height: 38vh !important;
    overflow-y: auto;
    border: 1px solid salmon;
    border-radius: 10px;
    padding: 20px;
}

.row1khsale {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: auto !important;
    width: 50vw !important;
    margin-top: -10px !important;
}


table#myTable td.thsmallkh{
    width: 170px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 13px;
    font-weight: bold !important;
    text-align: center;

}

table#myTable td.thsmallkh2{
    width: 250px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 13px;
    font-weight: bold !important;
    text-align: center;

}

table#myTable td.thsmallkh3{
    width: 550px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 13px;
    font-weight: bold !important;
    text-align: center;

}

.row1kh {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;  /* Align items vertically */
    justify-content: space-between;  /* Distribute space between children horizontally */
}

.row1kh100 {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;  /* Align items vertically */
    justify-content: space-between;  /* Distribute space between children horizontally */
}

.rightkhsaletop{
    display: flex;
    align-items:flex-end;
    align-self: center;
    padding-right: 10px;
    width: 700px;
    justify-content: flex-end;
}

.khadyaicon{
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.rightkhsale{
    display: flex;
    align-items: end;
    align-self: end;
    padding-right: 70px;
    width: 400px;
    justify-content: end;
}


.khsaleblack {
    font-family: 'Nunito', sans-serif !important;
    font-size: 12px;
    align-self: center;
    align-items: center;
    margin-top: 5px;
    margin-left: 5px;
    align-content: center;
    font-weight: bold;
}


.khsaleblack2 {
    font-family: 'Nunito', sans-serif !important;
    font-size: 12px;
    align-self: center;
    align-items: center;
    margin-top: 5px;
    margin-left: 20px;
    align-content: center;
    font-weight: bold;
}

.khsalered {
    font-family: 'Nunito', sans-serif !important;
    font-size: 16px;
    align-self: center;
    align-items: center;
    align-content: center;
    margin-top: 15px;
    font-weight: bold;
    color: var(--label);
    transition: transform 0.2s ease-in-out; /* Add transition for smooth scaling */
}

.khsalered:hover {
    cursor: pointer;
    transform: scale(1.5); /* Correct way to apply scale */
}


.rightkhsaletop {
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    align-self: center;
    align-items: center;
    align-content: center;
    margin-top: 15px;
    font-weight: bold;
    color: #0e6ebd;
    transition: transform 0.2s ease-in-out; /* Add transition for smooth scaling */
}

.rightkhsaletop:hover{
    font-weight: bolder !important;
    cursor: pointer;
    transform: scale(1.02) !important;
}