.imgcowtran{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 360px;
    height: 300px;
}

.line{
    width: 1160px !important;
    background-color: lightgray;
    height: 0.5px;
    border-color : red;
    margin-top: 5px;
  }

