.backmain{
    display: flex !important;
    flex-direction: row !important;
    margin-top: 1px !important;
    transition: opacity 1.5s, max-height 1.0s !important;

}

.custbox{
    display: flex;
    width: 60vw;
    height: 100%;
    display: flex;
    margin-top: 20px;
    margin-left: 5px;    
}

#table-to-xls{
    margin-top: 20px !important;
}

table#table-to-xls th.thsmall {
    font-size: 14px;
    font-weight: bold !important;
    background-color: var(--t6dark);
}

table#table-to-xls td.thsmall {
    font-size: 14px;
    font-weight: bold !important;
}

table#table-to-xls td.thsmallgreen {
    font-size: 14px;
    font-weight: bold !important;
    background-color: var(--on);
}

.backmain.hidden {
    opacity: 0 !important;
    pointer-events: none !important;
    max-height: 0 !important;
    margin-top: 10px !important;
}

.back:hover{
    transform: scale(1.1) !important;
}

.buttonsreport{
    display: flex !important; 
    flex-direction: row !important;
    justify-content: start !important;
    align-items: center !important;
    gap: 0rem !important;
    width: 60vw !important;
    
}

#search {
    width: 500px;
  }
  
.kendra>#cname {
    width: 500px !important;
    font-weight: bold !important;
  }
  
  #city {
    width: 220px !important;
    font-weight: bold !important;
  }
  
  #status {
    width: 220px !important;
    font-weight: bold !important;
  }

  #citynew {
    width: 150px !important;
    height: 20px !important;
    padding: 3px !important;
    font-size: 12px !important;
  }

.report{
    display: flex;
    flex-direction: column;
    margin-top: 1px;
    max-height: 30%; /* Set the initial height value */
    transition: opacity 1.5s, max-height 1.0s;
    
}

.report.hidden {
    opacity: 0;
    pointer-events: none;
    max-height: 0;
    margin-top: 10px;
}

.btn-close{
    margin-left: 10px;
}

.table-bordered{
    font-family: 'Yantramanav', sans-serif !important;
    font-size: 14px !important;
    font-family: 'Nunito', sans-serif !important;
    color: rgb(39, 37, 37);
    font-weight: normal !important;
    width: 85% !important;
}

.editmode{
    background-color: rgb(253, 250, 205) !important;
}

.boxnewkenra{
    border: 1px solid var(--t4darkextra);
    background-color: var(--t4darkextra);
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    margin-left: 25px;
    margin-bottom: 20px;
    height: 130px;
    width: 80%;
}
.boxnewkenra:hover{
    cursor: pointer;
    box-shadow:1px 0px 1px var(--t2);
    scale: 1.02;
}

.boxnewkenra>img{
    height: 110px;
    width: 110px;
}

.rownewkenra{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    gap: 5px;
    align-self: flex-start;
    color: whitesmoke;
    font-weight: bold;
}

.rownewkenra0top{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    align-self: flex-start;
    color: whitesmoke;
}

.iconnewkenra{
    height: 20px;
    width: 20px;
}