.rowreport{
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.mainconreport{
    display: flex;
    flex-direction: column;
    width: 22vw !important;
    margin-top: 1px !important;
}


.mainconreport2{
    display: flex;
    flex-direction: column;
    width: 30vw !important;
    margin-top: 1px !important;
}

.line70{
    width: 90%;
    background-color: #D3D3D8;
    height: 0.5px;
    border-color : red;
    margin-top: 5px;
  }