
.body_container {
    flex: 1 !important;
    height: 100vh !important; 
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    top: 0 !important;
  }
  
  .body{
    display: flex !important;
    height: 93% !important;
    justify-content: start !important;
    align-items: start !important;
    width: 100% !important;
    flex-direction: column !important;
    padding: 15px !important;
    margin: 10px !important;
    background: white !important; 
     /* backdrop-filter: blur(5.5px)!important; */
    border-radius:10px !important;
    border: 1px solid rgba( 255, 255, 255, 0.18 ) !important; 
  }
  
  .profileinfo{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
  
  .firstpart2{
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    flex-direction: row !important;
    width: 88% !important;
    
  }
  
  .firstpart2:hover{
    cursor: pointer;
  }
  
  .firstpart2>span{
    margin-left: 5px;
    color: black !important;
    border: none !important;
    font-family: 'Nunito', sans-serif !important;
    font-size: 21px !important;
  }
  
  .imglogo{
    width: 42px !important;
    height: 40px !important;
  }
  
   .next{
    width: 33px;
    margin-left: 12px;
  }
  
  .signout{
    border-radius: 10px;
    background: -webkit-linear-gradient(#FFC107, #FF9800);
    border: none;
    color: whitesmoke;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 15%;
    padding: 10px;
    font-family: 'Yantramanav', sans-serif;
    font-size: 14px;
  }
  
  .imgslide{
    height: 100px;
    width: 200px;
  }
  
  .line{
    width: 100%;
    background-color: #D3D3D8;
    height: 0.5px;
    border-color : red;
    margin-top: 5px;
  }