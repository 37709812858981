.table-containerauditnew{
    width: 85% !important;
    height: 43vh !important;
    overflow-y: auto;
    border: 1px solid salmon;
    border-radius: 10px;
    padding: 20px;
}

.mb-2>#khtype,
.mb-2>#type,
.mb-2>#khname,
.mb-2>#khcompany

{
    width: 220px !important;
    margin-top: 5px !important;
    font-weight: bold !important;
}