#searchsank{
    width: 40% !important;
}

.buttonsreportsank{
    width: 100% !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px !important;
}

.radio{
    display: flex;
    flex-direction: column;
    font-family: 'Nunito', sans-serif !important;
    font-weight: bold;
    font-size: 13px !important;
    color: var(--label);
    align-self: right;
}

.hori{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

table#myTablebill td.thsmallredd {

    width: 200px !important;
    color: var(--label);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    text-align: center;
    font-weight: bolder;
}

table#myTablebill td.thsmallgreenn {
    width: 200px !important;
    color: var(--green) !important;
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    text-align: center;
    font-weight: bolder;
}