.literdetailas{
    display: flex;
    flex-direction: column;
    gap: 5px;
}


.thsmallcustredsdf{
    font-family: 'Nunito', sans-serif !important;
    font-weight: bold !important; 
    font-size: 15px !important;
    color: var(--label) !important;
    align-self: center !important;
}

table#myTablebill td.thsmallcustred {
    width: 30px !important;
    font-family: 'Nunito', sans-serif !important;
    font-size: 15px !important;
    text-align: center !important;
    font-weight: bold !important;
    color: var(--label) !important;
}

table#myTablebill td.thsmallcustgreen {
    width: 30px !important;
    font-family: 'Nunito', sans-serif !important;
    font-size: 15px !important;
    text-align: center !important;
    font-weight: bold !important;
    background-color: var(--greenlight) !important;
}

table#myTablebill tr.thsmallcustred2 {
    width: 30px !important;
    font-family: 'Nunito', sans-serif !important;
    font-size: 15px !important;
    text-align: center !important;
    font-weight: bold !important;
    background-color: var(--label) !important;
}

table#myTablebill tr.thsmallcustgreen2 {
    width: 30px !important;
    font-family: 'Nunito', sans-serif !important;
    font-size: 15px !important;
    text-align: center !important;
    font-weight: bold !important;
    background-color: var(--green) !important;
}