
.boxtreport{
    border: 1px solid var(--label);
    background-color: var(--t1dark);
    border-radius: 5px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 400px;
}

.boxtreportbig5{
    display: flex;
    flex-direction: column;
    border: 1px solid var(--label);
    background-color: var(--t1dark);
    border-radius: 5px;
    padding: 20px;
    display: flex;
    justify-content: center;
    height: auto;
    margin-top: 750px;
    width: 600px;
    z-index: 100;
}

.boxtreportbig4{
    display: flex;
    flex-direction: column;
    border: 1px solid var(--label);
    background-color: var(--t1dark);
    border-radius: 5px;
    padding: 20px;
    display: flex;
    justify-content: center;
    height: auto;
    margin-top: 650px;
    width: 600px;
    z-index: 100;
}

.boxtreportbig3{
    display: flex;
    flex-direction: column;
    border: 1px solid var(--label);
    background-color: var(--t1dark);
    border-radius: 5px;
    padding: 20px;
    display: flex;
    justify-content: center;
    height: auto;
    margin-top: 500px;
    width: 600px;
    z-index: 100;
}

.boxtreportbig2{
    display: flex;
    flex-direction: column;
    border: 1px solid var(--label);
    background-color: var(--t1dark);
    border-radius: 5px;
    padding: 20px;
    display: flex;
    justify-content: center;
    height: auto;
    margin-top: 400px;
    width: 600px;
    z-index: 100;
}

.boxtreportbig{
    display: flex;
    flex-direction: column;
    border: 1px solid var(--label);
    background-color: var(--t1dark);
    border-radius: 5px;
    padding: 20px;
    display: flex;
    justify-content: center;
    height: auto;
    margin-top: 200px;
    width: 600px;
    z-index: 100;
}

.rowtreport{
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Ensure content is spread out with space between */
    align-items: center; /* Vertically align items to the center */
    width: 100% !important;
    align-content: center;
}

.rowtreportsimple{
    display: flex;
    gap: 5px;
    flex-direction: row;
}


.refreshtreport {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-top: -10px;
    transition: transform 0.3s ease;
    /* Add transition for smooth effect */
}

.refreshtreport:hover {
    transform: rotate(180deg);
    /* Rotate 180 degrees on hover */
}


.boxtreport:hover{
    cursor: pointer;
}

.boxtreport>#myTable{
    margin-top: 120px !important;
    width: 400px;
}

.boxtreport>#myTable2{
    width: 400px;
    margin-top: 14px;
}

.icontreport{
    height: 20px;
    width: 20px;
    margin-top: -10px;
}

.spantreport{
    margin-left: 5px;
    color: var(--label) !important;
    border: none !important;
    font-family: 'Nunito', sans-serif !important;
    font-size: 17px !important;
    margin-bottom: 10px;
  }

  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0.8; }
    100% { opacity: 1; }
  }
  
  .spantreportgreen {
    margin-left: 5px;
    color: var(--green) !important;
    border: none !important;
    font-family: 'Nunito', sans-serif !important;
    font-size: 17px !important;
    animation: blink 1s step-start infinite; /* Add this line for blinking */
  }
  