.helpfirst{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 20px;
    align-items: flex-start;
}
.helpmain{
    display: flex;
    padding: 10px;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
}

.smallimg{
    height: 30px !important;
    width: 30px !important;
}
.smallimg:hover{
    cursor: pointer;
    scale:1.1;
}

.headinghelp{
    font-weight: bold;
}