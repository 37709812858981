.table-containerauditentry {
    width: 85% !important;
    height: 35vh !important;
    overflow-y: auto;
    border: 1px solid salmon;
    border-radius: 10px;
    padding: 20px;
}

.au>#amt{
    width: 150px !important;
}