.rowsettings {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: auto !important;
  width: 90vw !important;
}

.row1mobile {
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: auto !important;
  width: 90vw !important;
}


.rowmobileuser{
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-left: 150px;
}

.row1mobile2 {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: auto !important;
  align-items: flex-start;
  justify-content: center;
}

.row1mobile3 {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: auto !important;
  width: 80vw !important;
}

.mobileverti {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: auto !important;
}

.autobox {
  display: flex;
  flex-direction: column;
  padding: 5px;
  justify-content: center;
  width: auto;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  gap: 5px;
  width: 100px;
}

.autobox2 {
  display: flex;
  flex-direction: column;
  padding: 5px;
  border: 1px solid lightgray;
  border-radius: 3px;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
}


.autoboxtime {
  display: flex;
  flex-direction: column;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}


.switch {
  position: relative;
  display: inline-block;
  align-self: center;
  width: 60px;
  height: 25px;
  margin-top: 3px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}


.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked+.slider {
  background-color: var(--on);
}

input:checked+.slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}




#myTablemobileuser {
  height: 1% !important;
}

.containermobileuser {
  width: 43% !important;
  height: 48vh !important;
  overflow-y: auto;
  border: 1px solid salmon;
  border-radius: 10px;
  padding: 10px;
}

table#myTablemobileuser th.thsmall {
  width: 100px !important;
  height: 28px !important;
  color: black !important;
  font-family: 'Nunito', sans-serif !important;
  font-size: 14px;
  font-weight: bolder;
  text-align: right;
  padding: 5px;
  /* Add this */
  background-color: var(--t3) !important;
  padding-right: 10px !important;
}


table#myTablemobileuser td.thsmall {
  width: 100px !important;
  height: 28px !important;
  color: black !important;
  font-family: 'Nunito', sans-serif !important;
  font-size: 14px;
  font-weight: bolder;
  text-align: right;
  padding: 7px;
  /* Add this */
  padding-right: 10px !important;
}

.settingsbox {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px lightgray solid;
  border-radius: 5px;
  padding: 5px 10px;
  width: 100px;
  cursor: pointer;
}

.settingsboxselected {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px lightgray solid;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: lightblue;
  width: 100px;
  color: var(--label) !important;
  cursor: pointer;
}

.settingsbox:hover {
  transform: translateY(-1px);
  background-color: lightblue;
  color: red !important;
}


.subheadmobileuser {
  font-weight: bold;
  font-size: 11px;
  margin-left: 5px;
  /* Adds space between the image and the text */
}

.subheadmobileuser2 {
  font-weight: bold;
  font-size: 12px;
  color: var(--green);
  margin-left: 5px;
  /* Adds space between the image and the text */
}

.boximgmobileuser {
  width: 20px;
  height: 20px;
  margin-top: -5px;
}

#timefrom,#timeto,#evntimefrom,#evntimeto,#mhsnf{
  width: 100px;
  font-family: 'Nunito', sans-serif !important;
  font-weight: bold;
  border-radius: 3px;
  font-size: 11px;
  padding: 5px;
}

#mhsnf,#gaysnf{
  width: 100px;
  font-family: 'Nunito', sans-serif !important;
  font-weight: bold;
  border-radius: 3px;
  font-size: 13px;
  text-align: center;
  margin-top: -50px;
  padding: 5px;
}