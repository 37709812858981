.firstrowrepair {
    display: flex;
    margin-top: 15px;
    width: auto !important;
    align-items: center;
    justify-content: start;
    gap: 10px;
}

.firstrowrepair>span {
    color: var(--label);
    font-weight: bold;
    font-size: small;
}

.ratemain2{
    width: 100% !important;
}

.title{
    display: flex;
    flex-direction: row;
    width: 100px !important;
}

#date,
#vibhag
{
    width: 220px !important;
}

#saverepair
{
    width: 330px !important;
}