.dialogmain{
    width: 360px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: flex-start !important;
    flex-direction: column !important;
    font-family: 'Yantramanav', sans-serif !important;
}
.icondialog{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    width: 100%;
}
.icondialog>img{
    height: 90px !important;
    width: 90px !important; 
    
}

.buttonclass{
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 15px;
    width: 100%;
    gap: 10px !important;
    font-size: 19px !important;
 }

.buttonend{
    padding: 5px 35px;
    border-radius: 15px;
    background: -webkit-linear-gradient(#ff9c07, #FF9800);
    border: none;
    color: whitesmoke;
    cursor: pointer;
 }

 .head{
    font-family: 'Yantramanav', sans-serif !important;
    font-size: 21px !important;
    color: rgb(41, 39, 39);
 }

 .subhead{
    font-family: 'Yantramanav', sans-serif !important;
    font-size: 17px !important;
    color: rgb(41, 39, 39);
 }

