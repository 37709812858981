table#myTablebill td.thsmallbank {
    background-color: white !important;
    width: 200px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    text-align: start !important;
    font-weight: bold;
}

table#myTablebill td.thsmallbank2 {
    background-color: white !important;
    width: 200px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    text-align: end !important;
    padding-right: 20px !important;
    font-weight: bold;
}



table#myTablebill td.thsmallbanktotal {
    background-color: lightgray !important;
    width: 200px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    text-align: end !important;
    padding-right: 20px !important;
    font-weight: bold;
}

#branchreport{
    width: 200px !important;
    margin-left: 10px !important;
}