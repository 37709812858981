table#myTablebill td.thsmallcustkhr {
    width: 30px !important;
    color: black;
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    text-align: center;
    font-weight: bolder !important;

}

table#myTablebill td.thsmallcustkhrcredit {
    width: 30px !important;
    color: darkgreen;
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    text-align: center;
    font-weight: bolder !important;

}

table#myTablebill td.thsmallcustkhrdebit {
    width: 30px !important;
    color: darkred;
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    text-align: center;
    font-weight: bolder !important;

}

.modaltitle{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    color: var(--label);
    font-size: 13px;
}

.modalhori{
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.modalverti{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.khadyabox{
    display: flex;
    flex-direction: row;
    padding: 10px;
    width: 50%;
    align-items: center;
    border: 1px solid black;
    border-radius: 5px;
    background-color: aquamarine;
}

.labelkh{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 13px;
    margin-top: 3px;
}

.redlable{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 13px;
    color: var(--label);
    margin-top: 3px;
}

.interbox{
    display: flex;
    flex-direction: column;
}

.kdr>#name{
    width: 300px !important;
}

.khreportrow{
    display: flex;
    flex-direction: row;
    gap: 10px;
}