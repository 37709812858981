.settingmain {
    display: flex;
    flex-direction: column;
}

.firstrow {
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
    margin-top: 3px !important;
    width: 100% !important;
}

.bb {
    display: flex;
    align-items: center !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-self: center;
    margin-top: 1px !important;
}

.bbt {
  display: none;
}

.form-control{
    width: 160px !important;
}

#mhthev,
#kapatbill,
#ikapatliter,
#mhvah,
#gaythev,
#hptabill,
#ikapatbill,
#gayvah
{
    width: 200px !important;
}

.icsettings:hover{
  cursor: pointer;
  scale: 1.01;
}

.button{
    border-radius: 10px;
    background: -webkit-linear-gradient(#FFC107, #FF9800);
    border: none;
    color: whitesmoke;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    align-self: center;
    display: flex;
    width: 150px;
    padding: 5px;
    font-family: 'Yantramanav', sans-serif;
    font-size: 14px;
    font-weight: bold;
    margin-top: 30px !important;
  }

  .button:hover{
    scale: 1.05;
    cursor: pointer;
  }

  .centered-input {
    text-align: center;
    font-weight: bold;
  }

  .table-containersettings {
    width: 85% !important;
    height: 200px !important;
    overflow-y: auto;
    border: 1px solid salmon;
    border-radius: 10px;
    padding: 20px;
  }
  
  /* Hide the scrollbar */
  .table-containersettings::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }
  
  /* Customize the scrollbar style */
  .table-containersettings::-webkit-scrollbar {
    width: 10px;
  }
  
  .table-containersettings::-webkit-scrollbar-thumb {
    background-color: #FF907B;
    border-radius: 5px; /* Adjust the value to control the roundness */
  }
  
  .table-containersettings::-webkit-scrollbar-thumb:hover {
    background-color: #FF907B;
  }
  