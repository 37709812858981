.sanklanmain {
    display: flex;
    flex-direction: column;
    width: 75vw;
}

.firstrowsank {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    margin-top: -15px !important;
}

.firstrow2 {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-between;
    width: 90vw;
    align-items: center;
    margin-top: 5px !important;
}

.subheadsank {
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    font-weight: bold !important;
    user-select: none;
    transition: transform 0.3s ease;
}

.prev {
    font-family: 'Nunito', sans-serif !important;
    font-size: 15px;
    font-weight: bold;
    user-select: none;
    color: var(--label);
}

.subheadsankcolor {
    font-family: 'Nunito', sans-serif !important;
    font-size: 16px;
    font-weight: bold;
    user-select: none;
    color: var(--pink);
    transition: transform 0.3s ease;
}
.inputfontname{
    width: 50px !important;
}
.subheadsankgreen {
    font-family: 'Nunito', sans-serif !important;
    font-size: 16px;
    font-weight: bold;
    color: var(--green);
    user-select: none;
    transition: transform 0.3s ease;
}

.inputfont{
    font-family: 'Nunito', sans-serif !important;
    font-size: 16px;
    font-weight: bold;
}

.linesank {
    width: 81vw;
    background-color: #D3D3D8;
    height: 0.5px;
    margin-top: 5px;
}

.row1sank {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: auto !important;
    width: 90vw;
}

#sname {
    width: 250px !important;
}

#kno,
#liter,
#fat,
#snf,
#comm,
#rate,
#amt {
    width: 95px !important;
}

table#myTable th.thsmall {
    background-color: var(--t1) !important;
    width: 200px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
}

table#myTable td.thsmall {
    width: 200px !important;
    color: rgb(27, 26, 26);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    font-weight: bold !important;
}

table#myTable td.thsmall2 {
    width: 200px !important;
    color: rgb(15, 14, 14) !important;
    font-family: 'Nunito', sans-serif !important;
    font-size: 15px;
}
table#myTable th.thlarge {
    background-color: var(--t1) !important;
    width: 600px !important;
}

table#myTable td.thlarge {
    width: 600px !important;
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    color: rgb(27, 26, 26);
}

.icsank:hover {
    cursor: pointer;
    scale: 1.1;
}

.colmain {
    display: flex;
    width: 100%;
}

.rowsank {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.tboxbelow {
    display: flex;
    padding: 10px;
    flex-direction: row;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid var(--t1);
    background-color: var(--t2dark) !important;
    margin-top: 20px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    transition: transform 0.3s ease;
}

.tboxbelow:hover {
    cursor: pointer;
    transform: translateY(-4px);
  }

.tbox {
    display: flex;
    padding: 10px;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid var(--t1);
    background-color: var(--t1dark) !important;
    height: auto;
    overflow: hidden;
    align-items: center;
    transition: height 2.0s ease-in-out;
    width: 450px;
}

.expanded {
    display: flex;
    position: absolute;
    margin-top: -31px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid var(--t1);
    background-color: var(--t1dark) !important;
    right: 0; 
    height: 400px;
    width: 500px;
    z-index: 2;
}


.tbox-container {
    position: relative;
}

.noti{
    margin-right: -30px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    margin-top: -10px !important;
}

.form-check{
    font-weight: bold !important;
    font-size: 14px !important;
    margin-top: -10px !important;
}


.tbox:hover {
    cursor: pointer;
}

.table-containersank {
    width: 90% !important;
    height: 50vh !important;
    overflow-y: auto;
    border: 1px solid salmon;
    border-radius: 10px;
    padding: 20px;
}

#myTable {
    width: 98% !important;
}

.table-containersank::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
}

/* Customize the scrollbar style */
.table-containersank::-webkit-scrollbar {
    width: 10px;
}

.table-containersank::-webkit-scrollbar-thumb {
    background-color: #FF907B;
    border-radius: 5px;
    /* Adjust the value to control the roundness */
}

.table-containersank::-webkit-scrollbar-thumb:hover {
    background-color: #FF907B;
}

.subheadsank:hover {
    cursor: pointer;
    transform: translateY(-4px);
}

.subheadsankcolor:hover{
    cursor: pointer;
    transform: translateY(-4px);
}

.subheadsankgreen {
    cursor: pointer;
    transform: translateY(-4px);
}

.back2{
    width: 20px;
    height: 20px;
    margin-left: 15px;
}

.back2:hover{
    cursor: pointer;
    scale: 1.01;
}

.row1{
    display: flex;
}

#name{
    width: 300px !important;
}


#khtype{
    width: 300px !important;
}

#time{
    width: 236px !important;
}
.tt> #type{
    width: 140px !important;
}

.tt> #amt{
    width: 100px !important;
}

.checkboxes{
    display: flex;
    flex-direction: column;
    gap: 0px;
}