#remark{
    width: 350px !important;
}

#type,#amt{
    width: 200px !important;
}

.table-containeruchal {
    width: 85% !important;
    height: 30vh !important;
    overflow-y: auto;
    border: 1px solid salmon;
    border-radius: 10px;
    padding: 20px;
}

.row1uchal {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: auto !important;
    width: 60vw !important;
    margin-top: -10px !important;

}

.row1uchal2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;  /* Align items vertically */
    justify-content: space-between;  /* Distribute space between children horizontally */
}

.uchalblack {
    font-family: 'Nunito', sans-serif !important;
    font-size: 12px;
    align-self: center;
    align-items: center;
    margin-top: 5px;
    margin-left: 4px;
    align-content: center;
    font-weight: bold;
}
