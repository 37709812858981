.knoo>#type,
.knoo>#time
 {
    width: 100px !important;
    height: 30px !important;
    font-size: 13px !important;
    font-weight: bold !important;
    padding: 3px !important;
    justify-content: center !important;
    align-items: center !important;
}

.knoo>#name,
.knoo>#kno,
.knoo>#date1,
.knoo>#date2
{
    height: 30px !important;
    font-size: 13px !important;
    font-weight: bold;
}


.knoo>#date {
    width: 150px !important;
    height: 30px !important;
}

#rowbykno{
    display: flex;
    margin-top: -13px !important;
}

.knoo>#liter,
.knoo>#fat,
.knoo>#snf,
.knoo>#rate,
.knoo>#amt,
.knoo>#literevn,
.knoo>#fatevn,
.knoo>#snfevn {
    width: 80px !important;
    margin-left: 3px !important;
    font-size: 14px !important;
    height: 30px !important;
}

.knoo>#literlblmor,
.knoo>#fatlblmor,
.knoo>#snflblmor,
.knoo>#ratelblmor,
.knoo>#amtlblmor,
.knoo>#literlblevn,
.knoo>#fatlblevn,
.knoo>#snflblevn,
.knoo>#ratelblevn .knoo>#amtlblevn {
    width: 80px !important;
    margin-left: 3px !important;
}

.below>.subheadsank {
    margin-top: 5px !important;
    width: 150px !important;
    margin-left: 3px !important;
    font-size: 14px !important;
    color: var(--green);

}

.vertikno{
    display: flex;
    flex-direction: column;
    margin-top: 4px !important;

}

.knoo>#rate,
.knoo>#rateevn {
    width: 80px !important;
    margin-left: 3px !important;
    font-size: 14px !important;
    height: 30px !important;

}

.knoo>#amt,
.knoo>#amtevn {
    width: 110px !important;
    margin-left: 3px !important;
    font-size: 14px !important;
    height: 30px !important;
}

.jmaa {
    margin-left: 20px !important;
}

.firstrow2kno {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-between;
    width: 90vw;
    align-items: center;
}

.rowkno {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}


.intial {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 15% !important;
}

.firstkno {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 32% !important;
}

.secondkno {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 34% !important;
    margin-left: 20px !important;

}

.intial2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 18% !important;
}

.firstkno2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 20px !important;
    width: 45% !important;
}

.secondkno2 {
    display: flex;
    flex-direction: row;
    align-items: start;
    margin-left: 20px !important;
    justify-content: start;
    width: 40% !important;
}

.byknorow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.midd {
    margin-top: 20px !important;
    marker-start: 10px !important;
}

.midd2 {
    margin-top: 20px !important;
    margin-left: 20px !important;
}

.middbelow {
    color: black !important;
    margin-left: 20px !important;
}

#secondkno {
    margin-right: -30px !important;
}

.byknolbl {
    color: var(--label) !important;
    font-weight: bold;
}

.icons {
    width: 18px !important;
    width: 18px !important;

}

.icons:hover {
    cursor: pointer !important;
    scale: 1.5 !important;
}

.table-containersankkno {
    width: 90% !important;
    height: 50vh !important;
    overflow-y: auto;
    border: 1px solid salmon;
    border-radius: 10px;
    padding: 5px !important;
}

table#myTable td.thsmallbykno {
    width: 100px !important;
    height: 28px !important;
    color: black !important;
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    font-weight: bolder;
    text-align: right;
    padding: 0;
    /* Add this */
    padding-right: 10px !important;
}


table#myTable th.thaction {
background-color: var(--t3) !important;
width: 70px !important;
height: 13px !important;
color: rgb(76, 75, 75);
font-family: 'Nunito', sans-serif !important;
font-size: 14px;
font-weight: bold;
text-align: right;
padding: 5px !important;
/* Add this */
padding-right: 10px !important;
}


table#myTable td.thaction {
    width: 50px !important;
    height: 13px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    background-color: var(--t5dark) !important;
    padding: 0;
    /* Add this */
    padding-right: 10px !important;
}


table#myTable td.thsmallbyknored {
    width: 100px !important;
    height: 13px !important;
    color: red;
    font-family: 'Nunito', sans-serif !important;
    font-size: 13px;
    font-weight: bolder !important;
    text-align: right;
    padding: 0;
    /* Add this */
    padding-right: 10px !important;
}

table#myTable th.thsmallkno {
    background-color: var(--t3) !important;
    width: 100px !important;
    height: 13px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    padding: 5px !important;
    /* Add this */
    padding-right: 10px !important;
}

.below{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 5px;
    gap: 40px;
    color: var(--green);
    font-weight: bold;
}
