table#myTablebill td.thsmallcust {
    width: 30px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
   
}

table#myTablebill td.thsmalledit {
    width: 30px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    background-color: rgb(253, 250, 205) !important;
}

table#myTablebill td.thsmallcustlarge {
    width: 400px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    text-align: center;
    font-weight: bold !important;
}

table#myTablebill th.thsmallcustlarge {
    width: 400px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    text-align: center;
    font-weight: bold !important;
}

.custname{
    font-family: 'Nunito', sans-serif !important;
    font-size: 15px;
    text-align: center;
    font-weight: bold !important;
    margin-top: -50px !important;
    color: var(--label);
    margin-bottom: 10px !important;
}