.mainbox{
    display: flex;
    flex-direction: column;

}
.aboutiq{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 5px 5px;
}
.aboutiq>span{
    font-weight: bold;
    font-size: 14px;
    margin-top: 6px;
    color: rgb(116, 112, 112) !important;
    text-shadow: none !important;
    border: none !important;
    font-family: 'Yantramanav', sans-serif !important;
}

.box{
    border: 1px solid var(--t1dark);
    border-radius: 5px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 130px;
    width: 130px;
}
.box:hover{
    cursor: pointer;
    box-shadow:1px 0px 1px var(--t1);
    scale: 1.1;
}

.box>img{
    height: 110px;
    width: 110px;
}

