.non-editable {
  font-weight: bold;
  /* Add any other desired styling */
}

.ratemain{
  display: flex;
  flex-direction: column;
  width: 90vw !important;
}

.raterow{
  display: flex;
  flex-direction: row;
  width: 100% !important;
}

.selection{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.line{
  width: 76vw !important;
  background-color: #D3D3D8;
  height: 0.5px;
  border-color : red;
}


.vline{
  width: 1px !important;
  background-color: #D3D3D8;
  height: 90px;
  border-color : red;
}

.line2{
  width: 100% !important;
  background-color: #D3D3D8;
  height: 0.5px;
  border-color : red;
}

.firstrow{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  width: auto !important;
}

.rowcenter{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  align-content: center;
  align-self: center;
  width: auto !important;
}

.firstrow>span{
  color: var(--label);
}

.aa{
    margin-top: 4px !important;
}

.copylogo{
  height: 30px;
  width: 30px;
  margin-top: 30px;
}

#mhrate,#gayrate{
  width: 100px !important;
}

.ratetype>#type{
  width: 20px !important;
  background-color: red !important;
}

#startFatInput,
#endFatInput,
#startSNFInput,
#endSNFInput
{
  width: 80px !important;
}

.bb{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    margin-left: 20px !important;
    margin-top: 7px !important;
    width: 270px !important;
}

.bb2{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin-top: 7px !important;
  width: 200px !important;
}

.subhead{
    font-family: 'Yantramanav', sans-serif !important;
    color: rgb(114, 107, 107);
    font-size: 20px !important;
}

.labelrate{
  font-family: 'Yantramanav', sans-serif !important;
  color: rgb(114, 107, 107);
  font-size: 15px !important;
}

.labelratered{
  font-family: 'Yantramanav', sans-serif !important;
  color: var(--label);
  font-size: 15px !important;
  margin-left: 100px;
}

.vibh{
  font-family: 'Yantramanav', sans-serif !important;
  color: black;
  font-size: 17px !important;
  width: 100% !important;
}

.a{
    visibility: hidden;
}

.rate>#vibhag{
  width: 150px !important;
}

.rate>#centerto{
  width: 400px !important;
}

.table-container {
    width: 85% !important;
    height: 55vh !important;
    overflow-y: auto;
    border: 1px solid salmon;
    border-radius: 10px;
    padding: 20px;
  }
  
  /* Hide the scrollbar */
  .table-container::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }
  
  /* Customize the scrollbar style */
  .table-container::-webkit-scrollbar {
    width: 10px;
  }
  
  .table-container::-webkit-scrollbar-thumb {
    background-color: #FF907B;
    border-radius: 5px; /* Adjust the value to control the roundness */
  }
  
  .table-container::-webkit-scrollbar-thumb:hover {
    background-color: #FF907B;
  }

  .rratemain{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 9px;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    border: 0.5px solid lightgray;
    background-color: rgb(198, 226, 243);
    margin-left: 90px;

  }

  .ratibrate{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 9px;
  
  }
  

