.backmaincust {
    display: flex !important;
    flex-direction: row !important;
    margin-top: 1px !important;
    transition: opacity 1.5s, max-height 1.0s !important;
}

#kno {
    color: var(--label);
    font-weight: bold;
}


.xx>#type {
    width: 220px !important;
}

.sizee {
    background-color: blue !important;
    width: 10px !important;
}

.custbox {
    display: flex;
    width: 50vw !important;
    height: 70vh !important;
    display: flex;
    margin-top: 20px;
    margin-left: 5px;
    flex-direction: column;
}

.lastimg {

    width: auto !important;
    align-items: end;
    align-self: flex-end;
}

.lastimg:hover {
    scale: 1.05;
    cursor: pointer;
}

.custhidden {
    height: 0px !important;
    display: none;
}

.rpthidden {
    height: 0px !important;
    display: none;
}

.back:hover {
    transform: scale(1.1) !important;
}

.row1 {
    display: flex;
    width: 100% !important;
    margin-top: -20px !important;
}

.form-control {
    width: 220px !important;
}

.row1formcust {
    display: flex;
    width: 100%;
    margin-top: -10px;
}


#bname {
    width: 445px !important;
}
#tag {
    width: 670px !important;
}

.mb-2>#mobno,
.mb-2>#pass,
.mb-2>#address {
    width: 220px !important;
    font-weight: bold !important;
}

#pass,
#cname,
#aadhar,
#accno,
#farmerid {
    width: 220px !important;
    font-weight: bold !important;
}

#mobileuser,
#vibhag,
#branch
{
    width: 220px !important;
}

.settingcust{
    margin-left: 10px !important;
    transition: transform 0.3s ease;
}


.settingcust:hover {
    scale: 1.5 !important;
    transform: rotate(180deg) !important;
  }

#obal
{
    width: 220px !important;
    color: var(--label);
    font-weight: bold !important;
}

#othev{
    width: 220px !important;
    color: var(--green);
    font-weight: bold !important;
}

.buttonsreport {
    display: flex !important;
    flex-direction: row !important;
    justify-content: start !important;
    align-items: center !important;
    gap: 0rem !important;
    width: 60vw !important;
}

table#myTable td.thsmall2cust{
    width: 200px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    font-weight: bold !important;
    text-align: center;
   
}

.firstrowcust {
    display: flex;
    flex-direction: row;
    width: 900px !important;
}

.ic:hover {
    cursor: pointer !important;
}

#search {
    width: 500px;
}

.bbb {
    width: 230px;
}

.bbbs {
    display: none !important;
    background-color: red;
}

.bbsank {
    width: 200px;
}

.report.hidden {
    height: 100vh !important;
}

.btn-close {
    margin-left: 10px;
}

.editmode {
    background-color: rgb(253, 250, 205) !important;
}

.table-containercust {
    width: 100% !important;
    height: 100% !important;
    overflow-y: auto;
    border: 1px solid salmon;
    border-radius: 10px;
    padding: 10px;
}

#myTable {
    height: 50% !important;
}

.table-containercust::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
}

/* Customize the scrollbar style */
.table-containercust::-webkit-scrollbar {
    width: 10px;
}

.table-containercust::-webkit-scrollbar-thumb {
    background-color: #FF907B;
    border-radius: 5px;
    /* Adjust the value to control the roundness */
}

.table-containercust::-webkit-scrollbar-thumb:hover {
    background-color: #FF907B;
}