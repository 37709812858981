#rname{
    width: 300px !important;
}

#rno,
#liter,
#rate,
#amt {
    width: 98px !important;
}

.rowsankratib{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
}


.rightratib{
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    border: 1px lightgray solid;
    border-radius: 5px;
    padding: 5px;
    padding-right: 10px;
    justify-content: center;
}

.rightratib:hover{
    transform: translateY(-1px);
    background-color: lightblue;
}

.backratib{
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.subheadsankratib {
    cursor: pointer;
    transform: translateY(-4px);
    font-weight: bold;
    align-self: center;
    justify-items: center;
    margin-top: 7px;
}