#address,#mobno,#mhcomm,#gaycomm{
    width: 150px !important;
}


.row1sangh {
    display: flex;
    flex-direction: row;
    width: 75%;
    gap: 5px;
}
