table#myTablebill th.thsmallnave {
    background-color: rgb(236, 186, 186) !important;
    width: 200px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    text-align: center;
}

table#myTablebill th.thsmalljma {
    background-color: rgb(182, 232, 190) !important;
    width: 200px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    text-align: center;
}

table#myTablebill th.thsmallcustlargenave {
    width: 400px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    text-align: center;
    background-color: rgb(236, 186, 186) !important;
    font-weight: bold !important;
}


table#myTablebill th.thsmallcustlargejma {
    background-color: rgb(182, 232, 190) !important;
    width: 200px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    text-align: center;
}