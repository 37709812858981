.mainbox {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100vw !important;
    height: 100vh !important;
    background-image: url("../../imgs/fullgrass.png") !important;
}

.firstrowlogin{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.secondlogo{
    display: flex;
    justify-content: flex-end !important;
    flex-direction: column;
    margin-right: -50px !important;
    margin-bottom: 30px !important;
    align-items: center !important;
}
.secondlogo:hover{
    cursor: pointer;
    scale: 1.1;
}
.verfont{
    font-family: 'Inter', sans-serif !important;
    font-weight: bold !important;
    font-size: 8px !important;
    margin-left: 20px !important;
}
.cowimg{
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    margin-top: -100px;
    margin-left: -30px;
}

.logologin{
    width: 200px !important;
    height:  70px !important;
    margin-bottom: -30px !important;
}

.leftimg {
    display: flex;
    background-color: blue;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 150px;
}

.grass {
    display: flex;
    width: 110px;
    height: 150px;
    margin-top: -105px;
    margin-left: 90px;
}

.heading {
    display: flex !important;
    color: rgb(241, 78, 28) !important;
    font-family: 'Inter', sans-serif !important;
    font-weight: bold !important;
    align-self: center !important;
    align-items: center !important;
    justify-content: center !important;
    font-weight: bolder !important;
    font-size: 15px;
    
}

#contact,
#password
{
    width: 100% !important;
    
}

.fullgrass {
    display: flex;
    width: 100vw;
    height: 50px;
}

.vh-100 {
    background-color: white !important;
    width: 100% !important;
    height: 100% !important;
   
}

.card{
  
    margin-top: -25px;
}
.form-control{
    width: 100% !important;
}

.heading {
    color: rgb(241, 78, 28) !important;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
}

.kharedi {
    font-family: 'Nunito', sans-serif !important;
    font-size: 13px !important;
    font-weight: bold  !important;
    color: var(--green) !important;
    margin-top: 100px !important;
    margin-bottom: 100px !important;
}

.kharedi:hover{
    cursor: pointer;
    font-size: 15px !important;
}

@media (max-width: 593px) {
    .mainbox {
        display: flex !important;
        flex-direction: column !important;
        width: 100vw !important;
        height: 100vh !important;
    }

    .vh-100 {
        background-color: #508bfc !important;
        width: 100% !important;
        height: 100% !important;
        padding-right: 10px;
    }

    .leftimg {
        display: flex;
        flex-direction: row;
        width: 100vw;
        height: 30%;
       
    }


    .rightlogin {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        align-items: center;
        height: 70%;
    }

    .leftimg>img {
        height: 5rem;
        width: 14rem;
        margin-top: 10px;
    }
}