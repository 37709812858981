.regifirst{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    align-items: flex-start;
}

.headingregi{
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    font-weight: bold;
    color: var(--green);
}

.headingregi2{
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    font-weight: bold;
}

.regimain{
    display: flex;
    padding: 10px;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
}

.regimain2{
    display: flex;
    padding: 10px;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    margin-top: -5px !important;
}