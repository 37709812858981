.rowbill {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: center;
    align-items: center;
    
}

.subheadbill {
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    font-weight: bold;
    margin: 5px;
    margin-top: 35px;
    user-select: none;
    transition: transform 0.3s ease;
}

.se {
    margin-left: 20px !important;
}

#knofrom,
#knoto {
    width: 130px !important;
}

#show,
#save,
#print {
    margin-top: 23px !important;
}

.table-containerbill {
    width: 90% !important;
    height: 66vh !important;
    overflow-y: auto;
    border: 1px solid salmon;
    border-radius: 10px;
    padding: 20px;
}

#myTablebill {
    width: 98% !important;
}
.form-check{
    margin-top: 5px !important;
    margin-right: 5px !important;
}

.checklbl{
    font-family: 'Nunito', sans-serif !important;
    font-size: 11px;
    font-weight: bold;
    
}

.check{
    margin-left: 0px !important;
}

.radiolabel{
    font-size: 12px !important;
}
#myTablebill thead th {
    background-color: var(--t1) !important;
    position: sticky;
    top: 0;
    z-index: 1; /* Ensures the headers stay above the table content */
  }
  

table#myTablebill th.thsmall {
    background-color: var(--t1) !important;
    width: 200px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    text-align: center;

}

table#myTablebill th.thsmallhide {
    background-color: var(--t1) !important;
    width: 00px !important;
    display: none;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    text-align: center;
}

table#myTablebill td.thsmall2 {
    width: 200px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    text-align: center;
}

table#myTablebill td.thsmallhide {
    width: 00px !important;
    display: none;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    text-align: center;
}
.note{
    font-family: 'Nunito', sans-serif !important;
    font-size: 13px;
    font-weight: bold;
    color: var(--label);
}

table#myTablebill td.thsmall2edit {
    width: 200px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    text-align: center;
    background-color: rgb(253, 250, 205) !important;
}

table#myTablebill td.thsmallred {
    width: 200px !important;
    color: var(--pink);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}

table#myTablebill td.thsmallgreen {
    width: 200px !important;
    color: var(--green);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}

table#myTablebill th.thlarge {
    background-color: var(--t1) !important;
    width: 600px !important;
    color: rgb(76, 75, 75);
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
    text-align: center;
}

.table-containerbill::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
}

/* Customize the scrollbar style */
.table-containerbill::-webkit-scrollbar {
    width: 10px;
}

.table-containerbill::-webkit-scrollbar-thumb {
    background-color: #FF907B;
    border-radius: 5px;
    /* Adjust the value to control the roundness */
}

.table-containerbill::-webkit-scrollbar-thumb:hover {
    background-color: #FF907B;
}

.subheadsank:hover {
    cursor: pointer;
    transform: translateY(-4px);
}

.subheadsankcolor:hover {
    cursor: pointer;
    transform: translateY(-4px);
}

.subheadsankgreen {
    cursor: pointer;
    transform: translateY(-4px);
}

.back2 {
    width: 20px;
    height: 20px;
    margin-left: 15px;
}

.back2:hover {
    cursor: pointer;
    scale: 1.01;
}

.row1 {
    display: flex;
}

#name {
    width: 300px !important;
}

.tt>#type {
    width: 140px !important;
}

.tt>#amt {
    width: 100px !important;
}

.editlogo {
    width: 13px;
    height: 13px;
    margin-bottom: 3px;
}

.editlogo:hover {
    cursor: pointer;
    scale: 1.5;
}

.mbill {
    display: flex;
    padding: 10px;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid var(--t1);
    background-color: var(--t1dark) !important;
    height: auto;
    overflow: hidden;
    align-items: center;
    transition: height 2.0s ease-in-out;
    width: 300px;
}

.billreport{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.newbill{
    display: flex;
    border: 1px solid gray;
    padding: 7px;
    flex-direction: column;
    margin-top: 7px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
}

.optionbill{
    display: flex;
    flex-direction: column;
}

.newbill:hover{
  cursor: pointer;
}

.firstrowbill {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-between;
    width: 100vw !important;
    align-items: center;
    margin-top: 5px !important;
}

.colmainbill {
    display: flex;
    width: 78vw;
}

.icdelete{
    width: 15px !important;
    height: 15px !important;
    align-self: center;
    margin-top: 5px;
}
.icdelete:hover{
    cursor: pointer;
    scale: 1.4;
}
